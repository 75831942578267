@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.side-bar{

    background: #fff;
    width: 320px;
    padding: 30px 0px;
    padding-bottom: 20px;

    display: block;
    height: 100%;
    overflow-y: auto;
    transition: width 1s;
    font-family: Montserrat;
}

.collapse-bar{
    width: 30px;
}

.collapse-hide{
    display: none;
}

.category{
    color:#979DA5;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.category-title{
    align-self: start;
    padding-left: 30px;
    padding-bottom: 10px;
    color:#BBB8C5;
    font-weight: 600;
}

.active{

}
.active a{
    color: #764BF2 !important;
}

.page-title{
    margin-left: 20px;
    color: #7b7880 !important;
    text-decoration: none;
    font-size: 15px;
}

.page-title-disabled{
    cursor: not-allowed !important;
    color: #ebebeb;
}

.active-img{
    color: #764BF2;
}

.disabled-img{
    opacity: 0.5;
}

.disabled:hover, .active:hover{

}

.disabled:hover{
    background: rgba(235, 235, 235, 0.2) !important;
}

.disabled a{
    cursor: default;
    color: #ddd !important;
}


.page:hover{
    background: #EFF0FF;
}

.no-hover-background{
    background: none !important;
}

.page{
    height: 45px;
    color: #364454;
    margin: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}