.areasSelect{
    width: 20%;
    border: 1px solid #D1D2D3;
    height: 40px;
    background-position-x: 50px;
    padding: 5px;
    font-size: 15px;
}

.areasSelect:disabled{
    background: rgba(0,0,0,0.023);
}

.Toastify__toast-container .Toastify__toast{
    text-align: left;
}

.single-col{
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
    width: 100%;
    cursor: pointer;
}

.dragging-row {
    display: table;
    width: 100%;
    background: #f9f9f9;
}

.active-col{
    background: rgb(71, 96, 241);
    color: white;

}

.switchBrowse{
    border: 1px solid #636E7B;
    border-radius: 3px;
    background: transparent;
    font-weight: 300;
    height: 35px;
    padding: 0px 15px;
}

.switchBrowse img{
    height: 18px;
    width: 18px;
}

.switchBrowse:hover{
    background: inherit !important;
}

.switchActive{
    background:#CFF1FF;
}

.browse {
    border-radius: 0px;
    height: 40px;
    width: 15%;
    font-size: 15px;
}

.deleteQuestion{
    cursor: pointer;
}

.deleteQuestion img{
    width: 25px;
    height: 25px;
}

.deleteQuestion:hover img{
    transition: rotate 0.5s;
    rotate: 90deg;
}

.header-field{
    height: 35px !important;
    max-width: 220px;
}

xmp, code{
    max-width: 100%;
    white-space: break-spaces;
    word-break: break-word;
}



.footer-buttons{
    margin-left: 10px;
    min-width: max-content;
    width: 80px;
}

