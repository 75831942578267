.toggle-option {
    padding: 0;
    margin: 0;
    background: white;
    border-radius: 50px;
    font-size: 11px;
    color: #DCDBDB;
    border: none;
    width: 100px;
    font-weight: bold;
    height: 23px;
    transition: 0.3s;
}

.toggle-option-active {
    background: rgb(71, 96, 241) !important;
    color: white !important;
}

.toggle-type {
    border-top: 1px solid #DCDBDB;
    border-bottom: 1px solid #DCDBDB;
    box-sizing: border-box;
    border-radius: 50px;
    display: inline-flex;
    padding: 3px;
    height: fit-content;
}
