body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #162B36;
  background: #EFF0FF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
  margin: auto;
}

div::-webkit-scrollbar-thumb {
  background-color: gray;
}

html, body {
  height: 100%;
}
body {
  display: flex;
  align-items: start;
  justify-content: start;
  font-family: "Nunito Sans";
  color: var(--blue);
  font-size: 1em;
}

div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}


.soft-shadow{
  box-shadow: 0px 1px 16px 0px #0000001B
}

.hard-shadow{
  box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
}

#root{
  height: 100%;
  width: 100%;
}