@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
:root {
    --blue: #0e0620;
    --white: #fff;
    --green: #2ccf6d;
}

button {
    font-family: "Nunito Sans";
}
ul {
    list-style-type: none;
    padding-inline-start: 35px;
}

h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
}
h2 {
    font-weight: bold;
}


.btnNF {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;
}
.btnNF.green {
    border: 4px solid var(--green);
    color: var(--blue);
}
.btnNF.green:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: var(--green);
    z-index: -1;
    transition: 0.2s ease;
}
.btnNF.green:hover {
    color: var(--white);
    background: var(--green);
    transition: 0.2s ease;
}
.btnNF.green:hover:before {
    width: 100%;
}
@media screen and (max-width: 768px) {
    body {
        display: block;
    }
    .container {
        margin-top: 70px;
        margin-bottom: 70px;
    }
}
