.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    height: 50px;
}

.icon {
    width: auto;
    height: 20px;
    margin-left: 10px;
}

.input-field {
    border: none;
    outline: none;
    width: 100%;
    background: transparent !important;
}

.input-field::placeholder {
    color: #C0C0C0;
}

.input-field:focus {
    box-shadow: none; /* Remove blue outline */
    background: none !important;
}


