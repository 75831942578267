.candidate-header th{
    font-weight: 300 !important;
    padding: 5px 10px;
}

.candidate-header{
    height: 30px !important;
    box-sizing: content-box;
}

.candidate-body td{
    font-weight: 300 !important;
    padding: 10px;
}

.candidate-body td{
    border-bottom: 1px solid rgb(239, 239, 239);
}

.failed{
    padding: 5px 15px;
    gap: 6px;
    width: 70px;
    height: 24px;
    background: rgba(179, 38, 30, 0.2);
    border-radius: 30px;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: rgba(179, 38, 30, 1);
}
.pending{
    padding: 5px 15px;
    gap: 6px;
    width: 70px;
    height: 24px;
    background: rgba(101, 101, 101, 0.28);
    border-radius: 30px;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: rgb(101, 101, 101);
}
.passed{
    padding: 5px 15px;
    gap: 6px;
    width: 70px;
    height: 24px;
    background: rgba(109, 174, 115, 0.2);
    border-radius: 30px;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: rgba(109, 174, 115, 1);
}

.candidate-header th{
    border-bottom: 1px solid rgb(239, 239, 239);
}

.candidate-header th{
    text-align: left;
}

.transformToHide{
    transition: visibility 1s;
    visibility: hidden;
}

.transformToShow{
    transition: opacity 1s;
    opacity: 1;
}

.candidate-details{
    filter: drop-shadow(0px 1px 16px rgba(48, 56, 75, 0.1));
    border-radius: 3px;

    box-sizing: border-box;
}
