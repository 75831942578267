.candidate-header th {
    font-weight: 300 !important;
    padding: 5px 10px;
}

.candidate-header {
    height: 30px !important;
    box-sizing: content-box;
}

.candidate-body td {
    font-weight: 300 !important;
    padding: 10px;
}

.candidate-body td {
    border-bottom: 1px solid rgb(239, 239, 239);
}

.candidate-header th {
    border-bottom: 1px solid rgb(239, 239, 239);
}

.candidate-header th {
    text-align: left;
}

.quizzes-taken{
    position: absolute;
    top: 28px;
    right: 28px;
    borderRadius: 5px;
    zIndex: 1;
    background: #FFF;
    width: 256px;
    height: max-content;
    padding: 25px;
}

.candidateOpsIcon {
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
}


.transformToHide {
    transition: visibility 1s;
    visibility: hidden;
}

.transformToShow {
    transition: opacity 1s;
    opacity: 1;
}

.candidate-link {
    color: #4A8CD3;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
}