.candidate-header th {
    font-weight: 300 !important;
    padding: 5px 10px;
}

.candidate-header {
    height: 30px !important;
    box-sizing: content-box;
}

.candidate-body td {
    font-weight: 300 !important;
    padding: 10px;
}

.tooltip{
    width: 300px;
}

.candidate-body td {
    border-bottom: 1px solid rgb(239, 239, 239);
}

.candidate-header th {
    border-bottom: 1px solid rgb(239, 239, 239);
}

.candidate-header th {
    text-align: left;
}

.transformToHide {
    transition: visibility 0.7s;
    visibility: hidden;
}

.transformToShow {
    transition: opacity 0.7s;
    opacity: 1;
}

.candidate-link {
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
}