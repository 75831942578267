.status{
    padding: 5px 15px;
    gap: 6px;
    width: 70px;
    height: 24px;
    border-radius: 30px;
    font-weight: bold !important;
    font-size: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
}


.failed{
    background: rgba(179, 38, 30, 0.2) !important;
    color: rgba(179, 38, 30, 1) !important;
}

.expired{
    background: #495057 !important;
    color: #e9ecef !important;
}


.pending{
    background: rgba(101, 101, 101, 0.28) !important;
    color: rgb(101, 101, 101) !important;
}
.passed{
    background: rgba(64, 197, 76, 0.2) !important;
    color: rgba(109, 174, 115, 1) !important;
}