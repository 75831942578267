#addItem {
    cursor: pointer;
    color: white;
    border-radius: 50%;
    background: #fff;
    color: #0d6efd;
    width: 20px;
    height: 20px;
    font-width: 900;
}

#removeItem {
    cursor: pointer;
    color: white;
    border-radius: 50%;
    background: red;
    color: #fff;
    width: 20px;
    height: 20px;
    font-weight: 900;
}

#listData {
    text-align: center;
    width: 220px;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 35px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #e2e2e2;
    z-index: 5;
    border-radius: 0 0 5px 5px;
}

.removeItem {
    color: red;
    cursor: pointer;
}

.listItem {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

.listWrapper {
    width: 250px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;

}

#listInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

}