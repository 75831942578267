.loginWrapper{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #F0F0FF !important;
    padding: 0;
    margin: 0;
}

.LoginContent{
    border-radius: 40px;
}

.p20{
    padding: 20px;
}

.loginButton{
    cursor: pointer;
    background: #774CF3;
    border:none;
    width: 100%;
    padding: 10px 15px;
    border-radius: 7px;
    color: white;

}

.LoginContent input{
    background:  transparent !important;
}
.input{

    border: 1px solid #D1D2D3;
    padding: 10px 15px;

    border-radius: 5px;
    width: 100%;
}
.input:focus-visible{
    outline: none !important;
}

.LoginContent input:-webkit-autofill{
    -webkit-text-decoration-color: transparent;
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.error{
    background: rgba(255, 148, 148, 0.20);
    border: 1px solid rgba(255, 0, 0, 0.29);
}

.login{
    cursor: pointer;
    background: #265bc4;
    border:none;
    width: 60%;
    padding: 15px;
    border-radius: 7px;
    color: white;
}

.login:active{
    background-color: #0a2e75;
    transition: background-color 0.5s;
    border:none;
    width: 60%;
    padding: 15px;
    border-radius: 7px;
    color: white;
}



.login:disabled{
    background-color: #88a5e1;
    transition: background-color 0.5s;
    border:none;
    width: 60%;
    padding: 15px;
    border-radius: 7px;
    color: white;
    cursor: not-allowed;
}

.sidebar{
    /* ff 3.6+ */
    background:-moz-linear-gradient(1deg, #285AC4FC 0%, #0D43B4 100%);
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(1deg, #285AC4FC 0%, #0D43B4 100%);
    /* opera 11.10+ */
    background:-o-linear-gradient(1deg, #285AC4FC 0%, #0D43B4 100%);
    /* ie 10+ */
    background:-ms-linear-gradient(1deg, #285AC4FC 0%, #0D43B4 100%);
    /* global 94%+ browsers support */
    background:linear-gradient(1deg, #285AC4FC 0%, #0D43B4 100%);
    border-radius: 15px 0px 0px 15px
}
.sidebar img{
    margin: 15px;
    width: 60%;
    transform: rotate3d(0,2,0,180deg);
    transform: skew(10deg);
}

.transformToHide{
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.react-switch-handle > div{
    text-align: center !important;
}

.form-switch{
    margin: 0;
    padding-right: 2.5em;
}

.fadeInElement{ animation: fadeIn 0.8s; }
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.transformToShow{
    transition: visibility 1s, opacity 1.2s;
    height: 400px;
    visibility: visible;
    opacity: 1;
}

.loginButton:active{
    background-color: #0a2e75;
    transition: background-color 0.5s;
    border:none;
}



.loginButton:disabled{
    background-color: #88a5e1;
    transition: background-color 0.5s;
    border:none;
    cursor: not-allowed;
}


.afterWrapper{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: right;
}

.confirmation{
    width: 60%;
    animation-name: fade-in;
    animation-duration: 1s;
}

@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}

.afterWrapper::after{
    position: relative;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 11px !important;

    font-size: 15px;
    font-weight: 500;
    z-index: 1001;
}

.usernameErr::after{
    content: "Name too short";
    color: red;
}

.firstNameErr::after{
    content: "First Name too short";
    color: red;
}

.lastNameErr::after{
    content: "Last Name too short";
    color: red;
}

.passwordErr::after{
    content: "Password too short";
    color: red;
}

.passwordErrRegex::after{
    content: "Password is too weak";
    color: red;
}

.emailErr::after{
     content: "Email address already exists";
     color: red;
 }

.shortEmailErr::after{
    content: "Email too short";
    color: red;
}

.emailWPErr::after{
    content: "Email is not associated with known workplace domains.";
    color: red;
}

.emailValid::after{
    content: "Email available!";
    color: #1ABC9C;
}

.emailInvalid::after{
    content: "Inavlid Email";
    color: red;
}