@media (max-width: 900px) {
    .pricing-packages{
        flex-direction: column !important;
    }
    .pricing-image{
        display: none;
    }
}

.selectedPlan:before{
    content: "Active Plan";
    background:rgb(255, 255, 255);
    color:rgb(71, 96, 241);
    width: 100%;
    padding:2px 0px;
    display: block;
    border-radius: 5px 5px 0px  0px;
    font-size: 15px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');