.navbar {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    background: transparent;
    padding: 8px 30px 0px;
    position: relative;
    z-index: 2;
    justify-content: space-between;
    align-content: center;
}

.hover-blue:hover{
    color:#3847cb;
}
.hover-blue{
    cursor: pointer;
}

.hover-underline:hover{
    text-decoration: underline;
}
.hover-underline{
    cursor: pointer;
}

.logo{
    justify-content: left !important;
    font-size: 20px;
    width: 25%;
    max-width: 250px;
}

.logo img{
    height: 50px;

}
.buttons img{
    height: 40px;
    width: 40px;
}
.buttons{
    display: flex;
    width: 18%;
    justify-content: space-between !important;
    align-items: center !important;
    max-width: 450px;
}

.search-wrapper{
    height: 40px;
    width: 360px;
    border: 1px solid #bababa;
    padding: 0px 15px;
    align-items: center;
    justify-content: space-between;
    display: flex;

}

.profile-holder div{
    line-height: 16px;
}

.search-wrapper input{
    border: none;
    width: calc(100% - 30px);
    height: 38px;
}

.search-wrapper input:focus-visible{
    outline: none;

}

.search-wrapper img{
    height: 20px;
}