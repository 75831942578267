.container{
    height: 95%;
    width: 80vw;
    max-height: 100% !important;
    position: relative;
}


.transparent-container{
    background: transparent;
}


.filled-container{
    background: #fff;
    box-shadow: 0px 1px 16px 0px #30384B1A;
}