.table-head th {
    font-weight: 300 !important;
    padding: 5px 10px;
}

.table-head {
    height: 30px !important;
    box-sizing: content-box;
}

.table-row td {
    font-weight: 300;
    padding: 5px 10px;
}

.table-row td {
    border-bottom: 1px solid rgb(239, 239, 239);
}

.table-head th {
    border-bottom: 1px solid rgb(239, 239, 239);
}

.table-head th {
    text-align: left;
}

.table{
    margin-bottom: 0;
}



